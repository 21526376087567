<template>
  <div v-loading="loaders.resource" class="admin-layout-design-in-program m-0 px-3 pt-4 pb-0 pr-2 w-50 w-50">
    <back-button class="ml-4" />
    <div class="ml-2 d-flex justify-content-between">
            <div>
                <h1>Manage Shop</h1>
            </div>
            <div>
                <base-button type="dark-blue" outline @click="$router.push({ name: 'EditShop', })"> <i
                        class="fa" :class="{ 'fa-pen': shop, 'fa-plus': !shop }"></i> {{ `${shop ? 'Edit' :
                            'Add'}` }}</base-button>
            </div>
        </div>
        <manage-shop v-if="shop" :manage-shop="shop" />
        <div v-else class="mt-4 cardStyle text-center">
            <p class="m-5 font-weight-bold">
                No Shop Data Found!
            </p>
        </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import ManageShop from "@/views/Components/SuperAdmin/ManageShop/Card";


export default {
  name: "Shop",
  components: {
    ManageShop,
    BackButton
  },
  data() {
    return {
      loaders: {
        resource: false
      },
      shop: null,
    }
  },
  created() {
    this.fetchShopData()
  },
  methods: {

    /**
     * Fetch the Shop Data
     */
    async fetchShopData() {
      let vm = this
      vm.loaders.resource = true
      try {
        const response = await vm.$store.dispatch('_fetchShopWebsite')
        if (response.data.data) vm.shop = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Shop',
          message: message
        })
      }
      vm.loaders.resource = false
    },

  }
}
</script>

<style scoped>

</style>
