<template>
    <div class="mt-4">
        <div class="cardStyle mb-4 p-4">
        <div class="row content">
            <div class="col-12">
                <div class="sub-content">
                    <div class=" d-flex justify-content-between">
                        <p class="sub-heading">URL</p>
                        <p class="status" :class="{'active':manageShop.status,'inactive':!manageShop.status}">{{ manageShop.status ? 'Active': 'Inactive' }}</p>
                    </div>
                    <div class="data d-flex justify-content-between align-items-center">
                        <p class="url">
                            <a target="_blank" :href="manageShop.url">{{ manageShop.url }}</a>
                        </p>
                        <el-tooltip class="item" effect="dark" :content="copyToolTip" placement="top-start">
                            <div class="copy" v-html="copySvg" @click="copyToClipboard(manageShop.url)"
                                @mouseleave="resetToolTip"></div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="sub-content" v-if="manageShop.image_url">
                    <p class="sub-heading">Uploaded Image</p>
                    <div class="data">
                        <el-image :src="manageShop.image_url" lazy @click="showPreviewModal" class="manage-shop-image" />
                    </div>
                </div>
                <div class="sub-content" v-if="manageShop.title">
                    <p class="sub-heading">Title</p>
                    <div class="data">
                        {{manageShop.title}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cardStyle p-4">
        <div class="row content">
            <div class="col-12">
                <div class="sub-content">
                    <div class=" d-flex justify-content-between">
                        <p class="sub-heading">Message Title</p>
                        <p class="status" :class="{'active':manageShop.message_status,'inactive':!manageShop.message_status}">{{ manageShop.message_status ? 'Active': 'Inactive' }}</p>
                    </div>
                    <div class="data" v-html="manageShop.message_title" v-if="manageShop.message_title">
                    </div>
                    <div class="data" v-else><p>N/A</p></div>
                </div>
                <div class="sub-content">
                    <p class="sub-heading">Message For Baritastic Shop</p>
                    <div class="data" v-html="manageShop.message" v-if="manageShop.message"></div>
                    <div class="data" v-else><p>N/A</p></div>
                </div>
            </div>
        </div>
    </div>
    <div class="modals">
        <shop-preview v-if="manageShop && manageShop.image_url" :image="manageShop.image_url" />
    </div>
    </div>
</template>
<script>
import coptToClipboard from '@/mixins/coptToClipboardMixin';
import ShopPreview from "@/views/Components/SuperAdmin/ManageShop/Preview.vue";

export default {
    props: ['manageShop'],
    components: {
        ShopPreview
    },
    mixins: [coptToClipboard],
    data() {
        return {}
    },
    methods: {
        showPreviewModal() {
            this.$store.commit('ManageShopModule/showPreviewModal')
        }
    },
    computed: {

    }
};
</script>
<style scoped lang="scss">
.manage-shop-image {
    cursor: pointer;
    position: relative;

    &:hover::after {
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: white;
        content: "Click to preview";
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        pointer-events: none;
    }
}
</style>
  