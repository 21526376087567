<template>
    <modal :show.sync="$store.state.ManageShopModule.modals.preview" size="sm" class="shop-modal-preview">
        <h5 slot="header" class="modal-title">Mobile App Preview</h5>
        <div class="d-flex justify-content-center">
            <el-image :src="image" v-if="image" class="shop-image" />
        </div>
    </modal>
</template>
<script>

export default {
    props: {
        image: {
            type: String,
            default: ''
        }
    }
};
</script>
<style lang="scss">
.shop-image {
    width: 178px;
    aspect-ratio: 1.7;
    border: 3px solid #ced4da;

    &::after {
        content: '';
        position: absolute;
        bottom: -55px;
        right: -45px;
        width: 100px;
        height: 100px;
        background-color: #832C44;
        border-radius: 50%;
        border: 2px solid #ced4da;
    }
}
.shop-modal-preview {
    .modal-header {
        padding-bottom: 0;
    }
    .modal-body {
        padding-top: 0.5rem;
    }
}
</style>